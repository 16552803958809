import LineChart from "../components/Dashboard/LineChart";
import CircleChart from "../components/Dashboard/CircleChart";
import AreaChart from "../components/Dashboard/AreaChart";
import PostedJobTable from "../components/Dashboard/PostedJobTable";
import CalendarDayView from "../components/CalendarDayView/CalendarDayView";
import EmployeesList from "../components/Dashboard/Employess";
import { useEffect, useState } from "react";
import fetchData from "../hook/fetchData";
import { getEnv } from "@urecruits/api";
import { CandidateJobsStatus } from "../enums/CandidateJobsEnums";
import { AuthGuard, SubscriptionGuard, useHasPermission, useSubscription } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_RECRUITMENT } = getEnv()

async function getEmployeeCount() {
  const array = []
  await fetchData(`${API_RECRUITMENT}/api/company/members/week-counts`).then(data => {
    if (data?.length) {
      data.reverse().forEach((emp, i) => {
        array.push({ value: emp.count, name: `Day ${7 - i}` })
      })
    }
  })
  return array
}
async function getJobsCount() {
  const array = []
  await fetchData(`${API_RECRUITMENT}/api/company/jobs/week-counts?status=publish`).then(data => {
    if (data?.length) {
      data.reverse().forEach((job, i) => {
        array.push({ value: job.count, name: `Day ${7 - i}` })
      })
    }
  })
  return array
}

async function getPendingJobs() {
  const array = []
  await fetchData(`${API_RECRUITMENT}/api/company/jobs/week-counts?status=approval`).then(data => {
    if (data?.length) {
      data.reverse().forEach((job, i) => {
        array.push({ uv: job.count, name: `${7 - i}`, data: job.date })
      })
    }
  })
  return array
}

async function getJobSummary() {
  const array = []
  await fetchData(`${API_RECRUITMENT}/api/company/jobs`)
    .then(data => {
      const jobs = data?.rows
      const publish = jobs?.filter(job => job.status === CandidateJobsStatus.PUBLISH).length || 0;
      const onhold = jobs?.filter(job => job.status === CandidateJobsStatus.ONHOLD).length || 0;
      const draft = jobs?.filter(job => job.status === CandidateJobsStatus.DRAFT).length || 0;
      const approval = jobs?.filter(job => job.status === CandidateJobsStatus.APPROVAL).length || 0;
      const rejected = jobs?.filter(job => job.status === CandidateJobsStatus.REJECTED).length || 0;
      const closed = jobs?.filter(job => job.status === CandidateJobsStatus.CLOSED).length || 0;

      array.push({
        totalJobsCount: data?.count || 0,
        summary: [
          { name: "Active", value: publish },
          { name: "On hold", value: onhold },
          { name: "Closed", value: closed },
          { name: "Pending", value: approval },
          { name: "Draft", value: draft }
        ]
      })
    })
  return array[0]
}


const AdminDashboard = () => {
  const [employeeCount, setEmployeeCount] = useState([]);
  const [jobsCounts, setJobsCount] = useState([]);
  const [pendingJobs, setPendingJobs] = useState([]);
  const [jobSummary, setJobSummary] = useState({ totalJobsCount: 0, summary: [] });
  const { checkUserPermission } = useHasPermission();
  const { checkCurrentPackageType } = useSubscription()

  useEffect(() => {
    async function getInfo() {
      try {
        checkUserPermission('team-members', 'view') && getEmployeeCount().then(data => setEmployeeCount(data))
        if (checkUserPermission('job-post', 'view')) {
          const [jobCount, jobSummary, pendingJobs] = await Promise.all([getJobsCount(), getJobSummary(), getPendingJobs()])
          setJobsCount(jobCount)
          setJobSummary(jobSummary)
          setPendingJobs(pendingJobs)
        }
      } catch (error) {
        console.log(error)
      }
    }
    checkCurrentPackageType(['recruitment', 'fullcycle']) && getInfo()
  }, [checkUserPermission('team-members', 'view'), checkUserPermission('job-post', 'view'), checkCurrentPackageType(['recruitment', 'fullcycle'])])

  return (
    <>
      <SubscriptionGuard planPackageType={['recruitment', 'fullcycle']}>
        <section className="dashboard">
          <h2 className="dashboard__headline">Home</h2>
          <div className="dashboard__inner admin">
              <AuthGuard module='team-members' permission='view' >
                <LineChart
                  data={employeeCount || []}
                  name={"New Team Members"}
                  position={"left"}
                />
              </AuthGuard>
              <AuthGuard module='job-post' permission='view' >
                <LineChart
                  data={jobsCounts || []}
                  name={"Posted Jobs"}
                  position={"right"}
                />
              </AuthGuard>
            <div className='span-two-row'>
              <AuthGuard module='job-post' permission='view' >
                <CalendarDayView view="admin" />
              </AuthGuard>
            </div>

              <AuthGuard module='job-post' permission='view' >
                <CircleChart data={jobSummary} />
                <AreaChart title={"Pending jobs"} data={pendingJobs} />
                <div className="span-two-col">
                <PostedJobTable styles={{}} />
                </div>
              </AuthGuard>
            <AuthGuard module='team-members' permission='view' >
              <EmployeesList />
            </AuthGuard>
          </div>

        </section>
      </SubscriptionGuard>
    </>
  );
};

export default AdminDashboard;


