import React from "react";
import EventView from "./EventView";
import { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { getEnv } from "@urecruits/api";
import axios from "axios";
import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const dayTimeList = [
  "1 AM",
  "2 AM",
  "3 AM",
  "4 AM",
  "5 AM",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
  "12 AM",
];

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']


const getTodayDateFormat = () => {
  const date = new Date()
  const dateformat = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  return `${dateformat} ${months[date.getMonth()]}`
}
const typesOfInterview = ["HR Audio Video Interview", "Technical/Coding Assessment", "Senior HR Audio Video Interview", "Technical Audio Video Interview", "Meet-up", "Live Task/Coding Assessment", "Take Home/Coding Assessment"]

function getDaysArray(year, month) {
  var numDaysInMonth, daysInWeek, daysIndex, index, i, l, daysArray;

  numDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  daysInWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  daysIndex = { Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6 };
  index = daysIndex[new Date(year, month - 1, 1)?.toString()?.split(" ")[0]];
  daysArray = [];

  for (i = 0, l = numDaysInMonth[month - 1]; i < l; i++) {
    daysArray?.push({
      day: i < 9 ? `0${i + 1}` : `${i + 1}`,
      type: daysInWeek[index++],
      date: new Date(year, month - 1, i + 1),
      isToday: i + 1 === new Date().getUTCDate(),
    });
    if (index == 7) index = 0;
  }

  return daysArray;
}

const CalendarDayView = ({ view = "candidate" }) => {
  const todayDate = new Date();
  const currentMonth = todayDate.getMonth() + 1;
  const currentYear = todayDate.getFullYear();
  const [eventLists, setEventLists] = useState([]);
  const [selectedDate, setSelectedDate] = useState(todayDate);
  const [loading, setLoading] = useState(false);
  const { API_RECRUITMENT, API_ASSESSMENT } = getEnv()
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [toastNotification, setToastNotification] = useState({
    state: false,
    message: "",
  });
  const daysList = getDaysArray(
    selectedYear,
    selectedMonth.toString()
  );
  const fetchEvents = async () => {
    try {
      setLoading(true);
      const result = await axios.get(
        `${API_ASSESSMENT}/api/calendar/all-event?date=${selectedDate}&timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`, getConfig()
      );
      if (result) {
        {
          setLoading(false);
          setEventLists(result?.data?.events.filter((item) => typesOfInterview?.includes(item?.description)));
        }
      }
    } catch (error) {
      setLoading(false);
      setToastNotification({
        state: true,
        message: error?.message,
      });
    }
  };

  const handleMiniCalendarClick = async (day) => {
    if (day?.target?.value !== null && day?.target?.value !== undefined) {
      const date = new Date(day.target.value)?.getUTCDate()
      const month = new Date(day.target.value)?.getUTCMonth()
      const year = new Date(day.target.value)?.getUTCFullYear()

      if (date) {
        const newDataList = await getDaysArray(
          year.toString(),
          month + 1
        );

        setSelectedYear(year);
        setSelectedMonth(month + 1);
        if (newDataList) {
          const newDate = newDataList?.find((data) => {
            return data?.day == date
          })
          if (newDate) {
            setSelectedDate(newDate?.date)
          }
        }
      }
    }
  };

  useEffect(() => {
    if (selectedDate) fetchEvents();
  }, [selectedDate]);

  const handleToastNotificationClose = () => {
    setToastNotification({
      state: false,
      message: "",
    });
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleToastNotificationClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <Snackbar
        open={toastNotification.state}
        onClose={handleToastNotificationClose}
        message={toastNotification.message}
        action={action}
        sx={{
          "& .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root": {
            background:
              "linear-gradient(125.2deg, #099c73 8.04%, #015462 127.26%)",
            color: "white",
          },
        }}
      />
      <div
        className={`calendar-day-view_container ${view === "admin" && "active"
          }`}>
        <div className="week-view">
          <div className="title">
            <h4>Today</h4>
            <input
              type="date"
              value={`${selectedDate.getFullYear()}-${selectedDate.getMonth() < 9 ? 0 : ''}${selectedDate.getMonth() + 1}-${selectedDate.getDate() < 10 ? 0 : ''}${selectedDate.getDate()}`}
              onInputCapture={handleMiniCalendarClick}
              min="2000-01-01"
              className="date-input"
            />
          </div>
          <div className="days-list">
            {daysList?.map((day) => (
              <div
                onClick={() => setSelectedDate(day?.date)}
                className={`day ${(day?.day ===
                  (selectedDate.getDate() < 10
                    ? `0${selectedDate.getDate()}`
                    : `${selectedDate.getDate()}`) ||
                  (day?.isToday &&
                    day?.day ===
                    (selectedDate.getDate() < 10
                      ? `0${selectedDate.getDate()}`
                      : `${selectedDate.getDate()}`))) &&
                  "active"
                  } `}
                key={day?.day}>
                {`${day?.day} ${day?.type}`}
              </div>
            ))}
          </div>
        </div>
        <div className="day-view">
          <div className={`day-time-list ${view == 'admin' ? 'admin' : 'candidate'}`}>
            {dayTimeList.map((dayTime, index) => {
              // Get the current slot hour range dynamically
              const [hourStr, period] = dayTime.split(" ");
              const startHour = period === "PM" && hourStr !== "12" ? parseInt(hourStr) + 12 : parseInt(hourStr);
              const nextHour = (startHour + 1) % 24; // Ensure it cycles correctly past 12 AM

              const isEventExist = eventLists.filter((event) => {
                const periods = new Date(event.startDate).toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })
                const [time, period] = periods.split(" ");
                const [hr, minute] = time.split(":");
                const eventHour = period === "PM" && hr !== "12" ? parseInt(hr) + 12 : parseInt(hr);
                return startHour <= eventHour && eventHour < nextHour;
              })
                .map(i => {
                  const startPeriod = new Date(i.startDate).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })
                  const endPeriod = new Date(i.endDate).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })
                  return { ...i, startTime: startPeriod, endTime: endPeriod }
                });

              return (
                <div className="list" key={dayTime}>
                  <div className="day-time">
                    {dayTime}
                  </div>

                  {isEventExist.length > 0 && !loading && (
                    <div className="event-list">
                      {isEventExist.map((event, index) => {
                        return <div className="right" key={event?.id}>
                          <EventView
                            startTime={event?.startTime}
                            description={event?.description}
                            endTime={event?.endTime}
                            title={event?.title}
                            jobId={event?.jobId}
                            roomId={event?.roomId}
                            candidateId={event?.candidateId || event?.id}
                          // disableEvent={todayDate>=selectedDate}
                          />
                        </div>
                      })}
                    </div>
                  )}
                  {loading && (
                    <div style={{ width: "70%" }}>
                      <Skeleton animation="wave" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default CalendarDayView;

