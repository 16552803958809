import { ShowToolTipComponent } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';
import arrowIc from '../../image/icon/top-left-arrow_ic.svg';
import { Link } from "react-router-dom";

const TotalCount = ({ title, value, label, link = '', tooltipText = '' }) => {

	return (
		<Link to={link || '/'} className={`total-count`}>
			<div className="total-count__head">
				<p className="total-count__head__headline">{title}</p>
				{
					!!tooltipText ?
						<ShowToolTipComponent text={tooltipText}>
							<img src={arrowIc} alt="arrow ic" className="total-count__head__icon" />
						</ShowToolTipComponent>
						:
						<img src={arrowIc} alt="arrow ic" className="total-count__head__icon" />
				}
			</div>
			<p className="total-count__value">{value}<span> {label}</span></p>
		</Link>
	)
}

export default TotalCount