import { store, useTypedSelector } from "../store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useLayoutEffect, useRef, useState, Dispatch, SetStateAction } from "react";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
import {
	setCompanyNameFilterCandidateJobs, setEducationFilterCandidateJobs,
	setExperienceFilterCandidateJobs, setFunctionalAreaFilterCandidateJobs, setIndustryFilterCandidateJobs, setJobTypeFilterCandidateJobs,
	setLimitCandidateJobs,
	setLocationFilterCandidateJobs, setPostedOnFilterCandidateJobs, setPreferableShiftFilterCandidateJobs,
	setSalaryMonthFilterCandidateJobs,
	setSalaryYearFilterCandidateJobs,
	setSearchValueCandidateJobs, setSkillsFilterCandidateJobs,
	setSortByFilterCandidateJobs,
	setFilterTypeCandidateJobs,
	changeApplyPopupCandidateJobs,
	setSortTypeFilterCandidateJobs, setTableViewCandidateJobs, setTotalCountCandidateJobs, setCurrentPageCandidateJobs, setOrderCandidateJobsColumns
} from "../store/reducers/candidateJobsReducer";
import useClickOutside from "../hook/useClickOutside";
import { sortingFunc } from "../utils/sortingFuncForTables";
import { toJSONLocal, toJSONLocalPlusDay } from "./CompanyJobs";
// import { CandidateJobsOrder } from "../components/HOCs/OrderPopupHOCs";
import TableSVG from "../components/SVG/TableSVG";
import ListSVG from "../components/SVG/ListSVG";
import SaveSVG from "../components/SVG/SaveSVG";
import ApplySVG from "../components/SVG/ApplySVG";
import SeeApplicationFormSVG from "../components/SVG/SeeApplicationFormSVG";
import { decodeToken, getEnv } from "@urecruits/api";
import axios from "axios";
import { Link } from "react-router-dom";
// import {setCurrentPageCompanies} from "../store/reducers/companiesReducer";
import CandidateJobsFilter from "../components/CandidateJobs/CandidateJobsFilter";
import ApplyPopupCandidateJobs from "../components/CandidateJobs/Popups/ApplyPopupCandidateJobs";

import TableListItem from "../components/CandidateJobs/List/TableListItem";
import { IColumns } from "@ucrecruits/globalstyle/types/table-types";
// import { setOrderCandidateJobsColumns } from "../store/reducers/candidateJobsReducer";
import defaultAvatar from "../image/temp-company.png";
import { getConfig, ShowToolTipComponent, TableCardViewComp } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";


const { API_RECRUITMENT } = getEnv();

const getTableData = store => store.candidate_jobs;
const getFiltersFunc = state => state.candidate_jobs.filters;
const getFilterDataFunc = state => state.candidate_jobs.filterInfo;
const getPaginationFunc = state => state.candidate_jobs.pagination;
const getFilterType = state => state.candidate_jobs.filterType;

const FilterItems = (state) => { return <CandidateJobsFilter activeTab={state} />; };
const dayPublishConvert = (publishDay: string): number => {
	return Math.round((Date.now() - new Date(publishDay).valueOf()) / 86400000);
};

// const FilterPopupInnerWrap = (setState) => {
// 	const filter = useTypedSelector(getFiltersFunc);
// 	const filterData = useTypedSelector(getFilterDataFunc);
// 	const pagination = useTypedSelector(getPaginationFunc);

// 	return (
// 		<FilterWrapper
// 			setFilterPopup={setState}
// 			filterData={filterData}
// 			filters={filter}
// 			limit={pagination.limit}
// 			resetFunc={resetFilters}
// 			submitFunc={setCurrentPage}
// 		>
// 			{FilterItems}
// 		</FilterWrapper>
// 	);
// };

// const getFilterPopupInnerWrap = () => {
// 	const filter = useTypedSelector(getFiltersFunc);
// 	const filterData = useTypedSelector(getFilterDataFunc);
// 	const pagination = useTypedSelector(getPaginationFunc);
// 	return {
// 		filterData,
// 		filter,
// 		limit: pagination.limit,
// 		resetFilters,
// 		setCurrentPage,
// 	}
// }

const onApplyHandler = () => {
	store.dispatch(changeApplyPopupCandidateJobs({ visible: false, jobId: 0, jobTitle: "" }))
}

const onSavedClicked = (jobId: number, setRefetch: Dispatch<SetStateAction<boolean>>, data: { applyjob?: boolean, saveJob?: boolean }) => {
	async function updateJobStatus(jobId: number, data: { applyJob?: boolean, saveJob?: boolean }) {
		const jobs = await axios.patch(`${API_RECRUITMENT}/api/subscribe/jobs/${jobId}`, data, getConfig())
		return jobs
	}
	try {
		updateJobStatus(jobId, data).then(res => {
			if (res.status === 200) {
				setRefetch(true);
			}
		})
	} catch (e) {
		console.log("error", e)
	}
}

const JobsScreen = () => {
	const [searchParams,setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	const table = useTypedSelector(getTableData);
	const filterType = useTypedSelector(getFilterType);

	const [ refetch, setRefetch ] = useState<boolean>(false);

	const [orderPopup, setOrderPopup] = useState(false);

	const [isFilterSubmit, setIsFilterSubmit] = useState(false);
	const [userId, setUserId] = useState();

	const orderPopupRef = useRef<null | HTMLButtonElement>(null);
	const tableRef = useRef(null);
	const setSort = (field)=>{
		store.dispatch(setSortByFilterCandidateJobs(field));
		const asc_desc = table.filters.sortBy === field && table.filters.sortType === 'ASC' ? 'DESC' : 'ASC';
		store.dispatch(setSortTypeFilterCandidateJobs(asc_desc));
	}

	const [columns, setColumns] = useState<Array<IColumns>>([
		{
			headerName: 'Job Title',
			field: 'title',
			pinnable: true,
			renderCell: (cellValue, rowData) => {
				return (<Link to={`/job/${rowData.id}`} className="candidate-table__link">{cellValue || ''}</Link>)
			},
			mobileRenderCell: (cellValue, rowData) => {
				return (<Link to={`/job/${rowData.id}`} className="candidate-table__link">{cellValue || ''}</Link>)
			},
			visibility: true,
			isMobileTitle: true,
			sort: true

		},
		{
			headerName: 'Company',
			field: 'company',
			renderCell: (cellValue, rowData) => {
				// return cellValue.name || '';
				return (<div className="candidate-table__company">
					<img
						src={!!cellValue.avatar ? cellValue.avatar : defaultAvatar} alt="avatar"
						className="candidate-table__company__avatar" />
					<p className="candidate-table__company__text">
						{cellValue.name}
					</p>
				</div>
				)
			},
			mobileRenderCell: (cellValue, rowData) => {
				return (<div className="candidate-table__company">
					<img
						src={!!cellValue.avatar ? cellValue.avatar : defaultAvatar} alt="avatar"
						className="candidate-table__company__avatar" />
					<p className="candidate-table__company__text">
						{cellValue.name}
					</p>
				</div>
				)
			},
			visibility: true,
		},
		{
			headerName: 'Job Location',
			field: 'locations',
			renderCell: (cellValue, rowData) => <div className="candidate-table__location">
				<p className="candidate-table__location__value">{cellValue?.map((location, index) => {
					return `${location.state}, ${location.city}${cellValue.length !== index + 1 ? ", " : ""}`;
				})}</p>
				{
					rowData.remoteLocation && (
						<p className="candidate-table__text gray">(Remote)</p>
					)
				}
			</div>,
			mobileRenderCell: (cellValue, rowData) => <div className="candidate-table__location">
				<p className="candidate-table__location__value">{cellValue?.map((location, index) => {
					return `${location.state}, ${location.city}${cellValue.length !== index + 1 ? ", " : ""}`;
				})}</p>
				{
					rowData.remoteLocation && (
						<p className="candidate-table__text gray">(Remote)</p>
					)
				}
			</div>,
			visibility: true,

		},
		{
			headerName: 'Experience',
			field: 'experience',
			renderCell: (cellValue, rowData) => <p className="candidate-table__text">{`${rowData.experienceMin}-${rowData.experienceMax}`} years</p>,
			visibility: true,

		},
		{
			headerName: 'Salary range',
			field: 'salary',
			renderCell: (cellValue, rowData) => <p className="candidate-table__text">{`$${rowData.salaryYearMin} - $${rowData.salaryYearMax}`} PA</p>,
			visibility: true,

		},
		{
			headerName: 'Main Skills',
			field: 'skills',
			renderCell: (cellValue, rowData) => {
				return cellValue ? cellValue?.map((name, index) => {
					return `${name}${cellValue.length !== index + 1 ? ", " : ""}`;
				}) : ""
			},
			mobileRenderCell: (cellValue, rowData) => {
				return cellValue ? cellValue?.map((name, index) => {
					return `${name}${cellValue.length !== index + 1 ? ", " : ""}`;
				}) : ""
			},
			visibility: true,
			className: ''
		},
		{
			headerName: 'Posted on',
			field: 'createdAt',
			renderCell: (cellValue, rowData) => <p className="candidate-table__text gray">{dayPublishConvert(cellValue) === 0 ? "today" : dayPublishConvert(cellValue) + " days ago"}</p>,
			mobileRenderCell: (cellValue, rowData) => { return dayPublishConvert(cellValue) === 0 ? "today" : dayPublishConvert(cellValue) + " days ago" },
			visibility: true,
		},
		{
			headerName: 'Education',
			field: 'education',
			visibility: true,
		},
		{
			headerName: 'Job type',
			field: 'jobType',
			visibility: true,
		},
		{
			headerName: 'Preferable shift',
			field: 'preferableShift',
			visibility: true,
		},
		{
			headerName: 'Industry type',
			field: 'industry',
			renderCell: (cellValue, rowData) => { return cellValue.label },
			mobileRenderCell: (cellValue, rowData) => { return cellValue.label },
			visibility: true,
		},
		{
			headerName: 'Functional area',
			field: 'functionalArea',
			visibility: true,
		},
		{
			headerName: 'Actions',
			field: 'actions',
			setActions: [{
				getAction: (row) => {
					const isClosed = row.status === "closed"
					if (isClosed) return;
					const isSaved = !!row.subscribes[0]?.saveJob || !!row.subscribes?.saveJob || false
					
					return {
						svg: <ShowToolTipComponent text={isSaved?'Unsave':'Save'}><SaveSVG /></ShowToolTipComponent>,
						title: 'Save',
						onClick: (row, event) => {
							onSavedClicked(row.id, setRefetch, { saveJob: !isSaved })
						},
						className: isSaved ? 'save active' : ''
					}
				},

			},
			{
				getAction: (row) => {
					const isClosed = row.status === "closed"
					if (isClosed) return;

					const isApplied = row.subscribes && (Array.isArray(row.subscribes) ? !!row.subscribes[0]?.applyJob : !!row.subscribes?.applyJob)

					return {
						svg: <ShowToolTipComponent text={isApplied ? 'Applied':'Apply'}><ApplySVG /></ShowToolTipComponent>,
						title: 'Apply',
						onClick: (row, event) => {
							if(isApplied){
								return;
							}
							store.dispatch(changeApplyPopupCandidateJobs({
								visible: true,
								jobId: row.id,
								jobTitle: row.title,
							}))

						},
						className: isApplied ? 'active' : ''
					}
				}
			},
				{
					getAction: (row) => {
						const hasSubscribes = (row.subscribes && row.subscribes.length > 0)
						const isClosed = row.status === "closed"
						const applicationForm = (row.applicationForm && row.applicationForm.length > 0) ? row.applicationForm[0] : undefined
						const isApplied = hasSubscribes && !!row.subscribes[0]?.applyJob
						if (isClosed || !isApplied || !applicationForm) return;

						return {
							svg: < SeeApplicationFormSVG />,
							title: 'See Application Form',
							onClick: (row, event) => {
								if (row.company) {
									navigate(`${row.id}/${row.company.id}/application-form`)
								} else {
									return
								}
							},
							className: 'active'
						}
					}
				}
			],
			visibility: true,

		}
	]);


	useEffect(()=>{
		const type = searchParams.get("type")
		if(type){
			if(type==="match"){
				store.dispatch(setFilterTypeCandidateJobs("matched"))
			}else if(type === 'save'){
				store.dispatch(setFilterTypeCandidateJobs("saved"))
			}
		}
		setSearchParams("")
	},[searchParams.get("type")])
	
	const getUserId = async () => await decodeToken().then(data => data["https://urecruits.com/userId"]);

	useLayoutEffect(() => {
		getUserId().then(id => setUserId(id))
	}, []);

	const filterArray = [
		table.filters.searchValue, table.filters.sortBy, table.filters.sortType, table.pagination, table.filters.locations, table.filters.postedOn, table.filters.experience, table.filters.salaryMonth, table.filters.salaryYear, table.filters.education, table.filters.skills, table.filters.company, table.filters.jobType, table.filters.industryType, table.filters.preferableShift, table.filters.functionalArea,
	];

	useTableClickAndDragScroll(tableRef);
	useClickOutside(orderPopupRef, setOrderPopup);

	const tdOrderCallback = useCallback((value) => sortingFunc(table, value, setSortTypeFilterCandidateJobs, setSortByFilterCandidateJobs, setCurrentPage), filterArray);

	const setSearchCallback = useCallback((value) => {
		store.dispatch(setSearchValueCandidateJobs(value));
		setCurrentPage(1);
	}, filterArray);

	const [filterString, setFilterString] = useState<string>('');
	const [isEmptyFilter, setIsEmptyFilter] = useState<boolean>(false);

	useEffect(() => {
		const filters = table.filters;
		if (isFilterSubmit && filters) {
			setFilterString(`${filters.locations ? filters.locations.map((x: any) => `&locations=${x.value}`).join("") : ""}
			${filters.postedOn ? "&createdAtFrom=" + toJSONLocal(filters.postedOn) : ""}
			${filters.postedOn ? "&createdAtTo=" + toJSONLocalPlusDay(filters.postedOn) : ""}
			${filters.salaryMonth[0] ? "&salaryMonthMin=" + filters.salaryMonth[0] : ""}
			${filters.salaryMonth[1] ? "&salaryMonthMax=" + filters.salaryMonth[1] : ""}
			${filters.salaryYear[0] ? "&salaryMonthMin=" + filters.salaryYear[0] : ""}
			${filters.salaryYear[1] ? "&salaryYearMax=" + filters.salaryYear[1] : ""}
			${filters.experience[0] ? "&experienceMin=" + filters.experience[0] : ""}
			${filters.experience[1] ? "&experienceMax=" + filters.experience[1] : ""}
			${filters.education ? "&education=" + filters.education.value : ""}
			${filters.skills ? filters.skills.map((name: any) => `&skills=${name}`).join("") : ""}
			${filters.company ? "&companyId=" + filters.company.id : ""}
			${filters.jobType ? "&jobType=" + filters.jobType.value : ""}
			${filters.industryType ? "&industryId=" + filters.industryType.id : ""}
			${filters.functionalArea ? "&functionalArea=" + filters.functionalArea.value : ""}
			${filters.preferableShift ? "&preferableShift=" + filters.preferableShift.value : ""}`);
		}
	}, [isFilterSubmit]);

	const createAPIString = () => {
		const filters = table.filters;
		const pagination = table.pagination;
		if (userId) {
			return `${API_RECRUITMENT}/api/${filterType === "matched" ? "job/match/jobs" : "job"}?limit=${pagination.limit}&offset=${(pagination.currentPage - 1) * pagination.limit}
			${filters.searchValue ? "&title=" + filters.searchValue : ""}
			${filterString}
			${filters.sortBy ? "&sortBy=" + filters.sortBy : ""}
			${filters.sortType ? "&sortType=" + filters.sortType : ""}
			${filterType ? "&filterType=" + filterType : ""}
			&currentUserId=${userId}`
		}
	}

	//TODO: change link
	const emptyTableCallback = useCallback(() => navigate("/job/create"), []);
	useEffect(() => {
		const cols = columns.filter(col => col.visibility).map((col, index) => {
			return {
				id: index,
				headerName: col.headerName,
				visibility: col.visibility,
				pinnable: col.pinnable
			}
		})
		store.dispatch(setOrderCandidateJobsColumns(cols))
	}, [columns])

	const handleFilterChange = (type) => {
		store.dispatch(setCurrentPageCandidateJobs(1))
		if (type === 'matched' && !columns.find(item => item.field === "match_percentage")) {
			setColumns((preVal) => {
				return [{
					headerName: '%',
					field: 'match_percentage',
					pinnable: true,
					renderCell: (cellValue, rowData) => {
						return (<Link to={`/job/${rowData.id}`} className="candidate-table__link">{`${Math.round(cellValue)}%` || ''}</Link>)
					},
					mobileRenderCell: (cellValue, rowData) => {
						return (<Link to={`/job/${rowData.id}`} className="candidate-table__link">{`${Math.round(cellValue)}%` || ''}</Link>)
					},
					visibility: true,
					isMobileTitle: false,
					className: "table__column__extra-small"
				}, ...preVal]
			})
		} else if (columns.find(item => item.field === "match_percentage")) {
			setColumns(preVal => preVal.filter(item => item.field !== "match_percentage"))
		}
		store.dispatch(setFilterTypeCandidateJobs(type))
	}

	const resetFilters = () => {
		store.dispatch(setSortByFilterCandidateJobs("id"));
		store.dispatch(setSortTypeFilterCandidateJobs("ASC"));
		store.dispatch(setSearchValueCandidateJobs(""));
		store.dispatch(setLocationFilterCandidateJobs([]));
		store.dispatch(setPostedOnFilterCandidateJobs(""));
		store.dispatch(setExperienceFilterCandidateJobs([0, 50]));
		store.dispatch(setSalaryMonthFilterCandidateJobs([0, 20000]));
		store.dispatch(setSalaryYearFilterCandidateJobs([0, 1000000]));
		store.dispatch(setEducationFilterCandidateJobs(""));
		store.dispatch(setSkillsFilterCandidateJobs([]));
		store.dispatch(setCompanyNameFilterCandidateJobs(""));
		store.dispatch(setJobTypeFilterCandidateJobs(""));
		store.dispatch(setPreferableShiftFilterCandidateJobs(""));
		store.dispatch(setIndustryFilterCandidateJobs(""));
		store.dispatch(setFunctionalAreaFilterCandidateJobs(""));
		setIsFilterSubmit(true);
	};

	return (
		<>
			<section className="candidate-jobs">
				<div className="candidate-jobs__head">
					<div className="candidate-jobs__head__left">
						<h2 className="candidate-jobs__head__headline">
							Jobs
						</h2>
						<div className="candidate-jobs__buttons">
							<button className={`candidate-jobs__buttons__item ${filterType === "" ? "active" : ""}`} onClick={() => handleFilterChange("")} >
								All jobs
							</button>
							<button className={`candidate-jobs__buttons__item ${filterType === "applied" ? "active" : ""}`} onClick={() => handleFilterChange("applied")} >
								My applied jobs
							</button>
							<button className={`candidate-jobs__buttons__item ${filterType === "saved" ? "active" : ""}`} onClick={() => handleFilterChange("saved")} >
								Saved Jobs
							</button>
							<button className={`candidate-jobs__buttons__item ${filterType === "matched" ? "active" : ""}`} onClick={() => handleFilterChange("matched")} >
								Matched Jobs
							</button>
						</div>
					</div>
					<div className="candidate-jobs__head__right">
						<div className="candidate-jobs__action">
							<div
								className={` candidate-jobs__action__item ${table.tableView === "table" ? "active" : ""}`}
								onClick={() => store.dispatch(setTableViewCandidateJobs("table"))}

							>
								<TableSVG />
							</div>
							<div
								className={` candidate-jobs__action__item ${table.tableView === "list" ? "active" : ""}`}
								onClick={() => store.dispatch(setTableViewCandidateJobs("list"))}
							>
								<ListSVG />
							</div>
						</div>
					</div>
				</div>
				<TableCardViewComp
                    tableViewType={table.tableView}
                    columns={columns}
					rowsData={{ api: createAPIString() }}
					sort={(field) => setSort(field)}
                    disableRow={{
                        text: 'No longer active',
                        getDisableStatus: (rowData) => {
                            if (rowData.status === "closed") return true;
                            return false
                        }
                    }}
					refetchApi={{refetch,setRefetch}}
                    searchField={{
                        searchValue: table.filters.searchValue,
                        setSearchValue: setSearchCallback,
                        placeholder: "Enter job title",
                    }}
                    pagination={{
                        limit: table.pagination.limit,
                        currentPage: table.pagination.currentPage,
                        totalCount: table.pagination.totalCount,
                        setTotalCount: (count: number) => { store.dispatch(setTotalCountCandidateJobs(count)) },
                        setCurrentPage: setCurrentPage,
                        setLimit: setLimitHandler,
                    }}
                    rearrangeColumns={{ setColumnOrder: setOrderCandidateJobsColumns }}
                    storeName={'candidate_jobs'}
                    store={store}
                    useTypedSelector={useTypedSelector}
                    tableListCard={({ row, columns, actions }) => <TableListItem row={row} columns={columns} actions={actions} key={row.id} />}
                    filters={{
                        filterData: useTypedSelector(getFilterDataFunc),
                        filter: useTypedSelector(getFiltersFunc),
                        resetFilters,
                        filterItems: FilterItems,
                        isFilterSubmit,
                        setIsFilterSubmit: (value: boolean) => (setIsFilterSubmit(value)),
                        isEmptyFilter,
                        setIsEmptyFilter: (value: boolean) => (setIsEmptyFilter(value))
                    }}
                />
			</section>
			{
				table.applyPopup.visible && (
					<ApplyPopupCandidateJobs onApplyJob={onApplyHandler} />
				)
			}
		</>
	);
};

export default JobsScreen;

const setLimitHandler = (value: any) => {
	store.dispatch(setLimitCandidateJobs(value));
};

export const setCurrentPage = (page: number) => {

	store.dispatch(setCurrentPageCandidateJobs(page));
};


