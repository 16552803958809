import React, { useEffect } from "react";
import "../styles/_full-calendar-view.scss";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import clockIcon from '../image/icon/clock_ic.svg';
import video from "../image/Video.svg";
import interactionPlugin from "@fullcalendar/interaction";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import fetchData, { patchData, postData } from "../hook/fetchData";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Loader from "../../recruitments/screen/VideoMeetLoader";
import Modal from "./ErrorModal";
import SuccessModal from "./SuccessfulModal";
import { decodeToken, getEnv } from "@urecruits/api";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { selectCustomStyle } from "../styles/selectCustomStyle";
import ProviderErrorModal from "../components/CalendarFullView/ProviderErrorModal";
const { API_RECRUITMENT } = getEnv();
const dayList = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

const FullCalendarView = () => {
  const [selectedDate, setSelectedData] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slotList, setSlotList] = useState([]);
  const [jobData, setJobData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [eventError, setEventError] = useState(false);
  const [eventSuccess, setEventSuccess] = useState(false);
  const [isRemindMe, setIsRemindMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [provider, setProvider] = useState(null);
  const [hasProvider, setHasProvider] = useState(true);
  const [interviewers, setInterviewers] = useState([]);
  const router = useNavigate();

  const [toastNotification, setToastNotification] = useState({
    state: false,
    message: "",
  });
  const [eventInput, setEventInput] = useState({
    time: "",
    timeType: "minutes",
  });
  const jobId = searchParams.get("jobId");
  const roundType = searchParams.get("roundType");
  const candidateId = searchParams.get("candidateId");
  const isReschedule = searchParams.get("reschedule") || false;
  const eventId = searchParams.get("eventId") || "";

  useEffect(() => {
    if (jobId) {
      fetchJobData();
      getProvider();
    }
  }, [jobId]);

  const fetchJobData = async () => {
    try {
      const result = await fetchData(
        `${API_RECRUITMENT}/api/job/${jobId}`
      ).then((data) => setJobData(data));
    } catch (error) {
      setEventError(true);
      console.log(error);
    }
  };

  useEffect(() => {
    if (jobData === null) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [jobData]);

  const getProvider = async () => {
    const apiUrl = `${API_RECRUITMENT}/api/integrations/provider`;
    try {
      await fetchData(apiUrl).then((result) => {
        if (result?.calendar) {
          setProvider(result.calendar);
          setHasProvider(true);
        } else {
          setHasProvider(false);
        }
      });
    } catch (error) {
      setEventError(true);
      setToastNotification({
        message: error.message,
        state: true,
      });
    }
  };

  useEffect(() => {
    if (selectedDate && provider) getAvailableSlot();
  }, [selectedDate]);

  const getAvailableSlot = async () => {
    if (selectedSlot) {
      setSelectedSlot(null);
    }
    setLoading(true);

    await fetchData(
      `${API_RECRUITMENT}/api/${provider === "gcal" ? "calendar" : provider
      }/appointment?date=${selectedDate.dateStr}&recruiterId=${jobData.authorId}&timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
    )
      .then((data) => {
        const similarSlots = data.similarSlots.map(i => formatTime(i.start, Intl.DateTimeFormat().resolvedOptions().timeZone))
        let retrievedSlots = similarSlots;
        if (isSameDayAsToday(selectedDate.date)) {
          retrievedSlots = getUpcomingTimeSlots(retrievedSlots);
        }
        setSlotList(retrievedSlots);
        setLoading(false);
      })
      .catch((error) => {
        setEventError(true);
        setToastNotification({
          state: true,
          message: error?.message,
        });
        setLoading(false);
      });
  };
  const handleAppointmentBooking = async () => {
    setLoader(true);
    if (!selectedDate) {
      alert("Please select the date to schedule the meeting");
      setLoader(false);
      return;
    }
    if (!selectedSlot) {
      alert("Please select the slot to schedule the meeting");
      setLoader(false);
      return;
    }
    if (!jobData) {
      alert("Don't have Job Data");
      setLoader(false);
      return;
    }
    if (isRemindMe && (!eventInput.time || !eventInput.timeType)) {
      alert("Please select the time for remind me");
      setLoader(false);
      return;
    }
    const { utcEndDate, utcStartDate } = convertTimeSlotDateToUTC(selectedDate.date, selectedSlot);
    const body = {
      date: formattedDate(selectedDate?.dateStr),
      slot: selectedSlot,
      eventTitle: jobData?.title,
      remindMe: isRemindMe,
      jobId: jobId,
      candidateId,
      eventId,
      utcStartDate,
      utcEndDate,
      interviewers: interviewers?.map((item) => item.value),
      timeBefore: `${eventInput?.time} ${eventInput?.timeType}`,
      recruiterId: jobData?.recruiterId,
      description: roundType,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    try {
      if (isReschedule) {
        await patchData(
          `${API_RECRUITMENT}/api/${provider === "gcal" ? "calendar" : provider
          }/reschedule-appointment`,
          body
        ).then((data) => {
          setLoader(false);
          setEventSuccess(true);
        });
        return;
      }
      await postData(
        `${API_RECRUITMENT}/api/${provider === "gcal" ? "calendar" : provider
        }/book-appointment`,
        body
      ).then((data) => {
        setLoader(false);
        setEventSuccess(true);
      });
    } catch (error) {
      setLoader(false);
      setEventError(true);
      setToastNotification({
        state: true,
        message: error?.message,
      });
    }
  };

  const liveCodingTime = () => {
    if (!selectedSlot) return "9:00 am - 10:00 am";
    const index = slotList.indexOf(selectedSlot);
    if (index <= slotList.length - 3)
      return `${slotList[index]} - ${slotList[index + 1]}`;
    else if (index === slotList.length - 2)
      return `${slotList[index]} - 5:00 PM`;
    else return `${slotList[index]} - 6:00 PM`;
  };

  const handleToastNotificationClose = () => {
    setToastNotification({
      state: false,
      message: "",
    });
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleToastNotificationClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const handleDateClick = (info) => {
    // Check if the clicked date is in the past
    const clickedDate = new Date(info.date);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (clickedDate < currentDate) {
      return;
    }

    if (selectedDate !== null) {
      selectedDate.dayEl.classList.remove("selected");
    }
    info.dayEl.classList.add("selected");
    setSelectedData(info);
  };

  return (
    <>
      <Snackbar
        open={toastNotification.state}
        onClose={handleToastNotificationClose}
        message={toastNotification.message}
        action={action}
        sx={{
          "& .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root": {
            background:
              "linear-gradient(125.2deg, #099c73 8.04%, #015462 127.26%)",
            color: "white",
          },
        }}
      />
      {loader && <Loader />}
      <div className="calendar-screen">
        <div className="calendar_heading">Select Date & Time</div>
        <div className="calendar_sub-heading">
          Please choose a convenient time and day for the {roundType}. You can
          select available dates and times below.{" "}
        </div>
        <div className="calendar_container">
          <div className="calendar_container-left">
            <div className="calendar_view">
              <div className="table-headers">
                {dayList.map((day) => {
                  return <div key={day}>{day}</div>;
                })}
              </div>
              <div style={{ width: "100%", height: "400px" }}>
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  height="100%"
                  eventContent={(info) => {
                    const eventDate = new Date(info.event.start);
                    const currentDate = new Date();
                    const isPastDate = eventDate < currentDate;

                    const classNames = ["event-content"];
                    if (isPastDate) {
                      classNames.push("past-date");
                    }

                    return { classNames };
                  }}
                  dateClick={handleDateClick}
                  aspectRatio={6 / 3}
                  views={{
                    timeGridWeek: {
                      allDaySlot: false,
                    },
                    timeGridDay: {
                      allDaySlot: false,
                    },
                  }}
                  titleFormat={{
                    month: "short",
                    year: "numeric",
                  }}
                  headerToolbar={{
                    start: "prev,next",
                    center: "title",
                    right: "",
                  }}
                />
              </div>
            </div>
            <div className="calendar_slot">
              <div className="calendar_slot-timing">
                Available time for{" "}
                <span className="calendar_slot-timing-text">
                  {selectedDate?.dateStr}
                </span>
              </div>
              {loading && (
                <>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </>
              )}
              {selectedDate && !loading && (
                <div className="calendar_slot-options">
                  {slotList?.map((slot) => (
                    <div
                      className={`${selectedSlot === slot
                        ? "calendar_slot-option active "
                        : "calendar_slot-option"
                        }`}
                      key="slot"
                      onClick={() => setSelectedSlot(slot)}
                    >
                      {slot}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="calendar_container-right">
            <div className="heading">
              {`${roundType}`}
            </div>
            <div className="timing-details">
              <div className="timing-detail-list">
                <img src={clockIcon} alt="Clock icon" className="img1"/>
                <span>1 Hrs</span>
              </div>
              <div className="timing-detail-list">
                <img src={video} className="img1" alt="not found" />
                <span>
                  Detailed information about the interview will appear after
                  confirmation
                </span>
              </div>
            </div>
            <div style={{ borderBottom: "1px solid #DFE2E6" }} />
            <div className="event-details">
              <div className="left"></div>
              <div className="right">
                <div className="text-1">{liveCodingTime()}</div>
                <div className="text-2">{roundType}</div>
                <div className="text-3">
                  {jobData
                    ? `Interview on Job Position of ${jobData.title} Role at ${jobData.companyName}`
                    : ` Interview on position UI/UX designer in Game of Trohne`}
                </div>
              </div>
            </div>
            <div className="option">
              <input
                onClick={() => setIsRemindMe((prev) => !prev)}
                type="checkbox"
              />
              <span>Remind me before</span>
              <Tooltip title="Must be between 0 and 40,320 minutes">
                <input
                  style={{ width: "42px", borderBottom: "1px solid black" }}
                  onChange={(event) => {
                    setEventInput({
                      time: event.target.value,
                      timeType: eventInput.timeType,
                    });
                  }}
                />
              </Tooltip>

              <span style={{ fontWeight: 600 }}>
                <select
                  onChange={(event) =>
                    setEventInput({
                      timeType: event.target.value,
                      time: eventInput.time,
                    })
                  }
                  name="slot"
                  id="slot"
                >
                  <option value="minutes">Minutes</option>
                  <option value="hours">Hours</option>
                  <option value="days">Days</option>
                  <option value="weeks">Weeks</option>
                </select>
              </span>
            </div>
            {candidateId && !isReschedule && (
              <AsyncSelect
                cacheOptions
                loadOptions={() => interviewersCallbackFunc()}
                isMulti
                defaultOptions
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(currentValue: any) => {
                  setInterviewers(currentValue);
                }}
                value={interviewers}
                placeholder={"Select interviewers"}
                components={{
                  Option,
                }}
                styles={selectCustomStyle}
                id="openings"
                instanceId="openings"
              />
            )}
          </div>
        </div>
        <div className="calendar-screen_bottom">
          <button onClick={() => router("/")}>Cancel</button>
          <button onClick={handleAppointmentBooking} className="button">
            Submit
          </button>
        </div>
      </div>
      {eventSuccess && <SuccessModal data={jobData.title} />}
      {eventError && <Modal />}
      {!hasProvider && (
        <ProviderErrorModal onCancel={() => setHasProvider(true)} />
      )}
    </>
  );
};

export default FullCalendarView;

const interviewersCallbackFunc = async () => {
  const getUserId = async () => await decodeToken().then(data => data["https://urecruits.com/userId"]);
  const userId = await getUserId();
  const resData = await fetchData(
    `${API_RECRUITMENT}/api/company/get-lead-users?${userId ? `excludedId=${userId}` : ''}`
  );

  return resData.map((item) => {
    return {
      value: {
        id: item.id,
        name: `${item.firstname} ${item.lastname}`,
        email: item.email,
      },
      label: `${item.firstname} ${item.lastname}`,
      id: item.id,
    };
  });
};

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props} className="select-component">
        <input
          id={props.value}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={props.value}>
          <span />
        </label>
        <p>{props.label}</p>
      </components.Option>
    </div>
  );
};

const timeStringToDate = (timeString: string): Date => {
  const [time, period] = timeString.split(" ");
  const [hours, minutes] = time.split(":").map(Number);
  const date = new Date();
  let hourValue = hours;

  if (period === "PM" && hourValue !== 12) {
    hourValue += 12;
  }
  if (period === "AM" && hourValue === 12) {
    hourValue = 0;
  }

  date.setHours(hourValue, minutes, 0, 0);
  return date;
};

const getUpcomingTimeSlots = (timeStrings: string[]): string[] => {
  const now = new Date();
  return timeStrings.filter((timeString) => {
    const timeDate = timeStringToDate(timeString);
    return timeDate > now;
  });
};

const isSameDayAsToday = (selectedDate: Date) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return selectedDate.getTime() == currentDate.getTime();
};

const formatTime = (time, timeZone = 'UTC') => {
  const date = new Date(time).toLocaleString("en-US", {
    timeZone,
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return date;
}

const convertTimeSlotDateToUTC = (selectedDate, selectedSlot) => {
  const [time, modifier] = selectedSlot.split(" ");
  let [hours, minutes] = time.split(":").map(Number);

  if (modifier === "PM" && hours !== 12) {
    hours += 12;
  } else if (modifier === "AM" && hours === 12) {
    hours = 0;
  }

  selectedDate.setHours(hours, minutes, 0, 0);

  // Convert to UTC
  const utcStartDate = selectedDate.toISOString();

  // **Add 1 hour**
  selectedDate.setHours(selectedDate.getHours() + 1);
  const utcEndDate = selectedDate.toISOString();

  return { utcStartDate, utcEndDate };
}
const formattedDate = startDate => startDate
  ? new Date(startDate).toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  }).replace(/\//g, "-")
  : "";