import { useEffect, useState } from 'react';
import fetchData from '../../hook/fetchData';
import { getEnv } from '@urecruits/api';
import { Stack, Skeleton } from '@mui/material';
import { DashboardEmptyDataComponent } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';
import { useNavigate } from 'react-router-dom';
import { findActiveRound } from '../../utils/rounds';
const clockIc = require('../../image/icon/clock_ic.svg')
const { API_RECRUITMENT } = getEnv()

const typesOfInterview = ["HR Audio Video Interview", "Technical/Coding Assessment", "Senior HR Audio Video Interview", "Technical Audio Video Interview", "Live Task/Coding Assessment"]
const typesOfAssessment = ["Functional/Domain Assessment", "Take Home/Coding Assessment"]

const PendingTasks = () => {
    const [tasks, setTasks] = useState([])
    const [loading, setLoading] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)

    useEffect(() => {
        fetchData(`${API_RECRUITMENT}/api/round/candidate`)
            .then(data => {
                const items = transformArray(data)
                if (items && items?.length) {
                    setTasks(items)
                } else {
                    setIsEmpty(true)
                }
                setLoading(false)
            }).catch(err => {
                setLoading(false)
                setIsEmpty(true)
            })
    }, [])
    return (
        <div className="candidate-tasks">
            <div className="candidate-tasks__headline">
                Pending Tasks {!!tasks.length && <span>- {tasks.length} right now</span>}
            </div>
            <ul className="candidate-tasks__list">
                {loading && <Stack height={"100%"}>
                    <Skeleton animation="wave" />
                </Stack>}
                {isEmpty && !loading && <DashboardEmptyDataComponent />}
                {!!tasks.length && !loading && tasks.map((i, index) => {
                    return <ListItem task={i} key={index} />

                })}
            </ul>
        </div>
    );
}

const ListItem = ({ task }) => {
    const navigate = useNavigate()
    const startDate = task.startDate && new Date(task.startDate)
    const endDate = task.endDate && new Date(task.endDate)
    const deadline = task.deadline && new Date(task.deadline)
    const currentTime = new Date();

    const isExpired = deadline && currentTime > deadline;

    return <li className="candidate-tasks__item">
        <p className="candidate-tasks__item__name">{task.title}</p>
        <div className='candidate-tasks__item__action'>
        <p className="candidate-tasks__item__company">{task.description}</p>
            {task.startDate && task.endDate &&
                <div className='candidate-tasks__item__timestamp'>
                    <p>Date: <span>{startDate.toLocaleDateString()}</span></p>
                    <p className='candidate-tasks__item__timestamp__time'>
                        Time: <img src={clockIc} alt="clock icon" /> <span>{transformDate(startDate)} - {transformDate(endDate)}
                        </span>
                    </p>
                </div>
            }
            {task.deadline &&
                <div className='candidate-tasks__item__timestamp'>
                    <p>Deadline: <span>{deadline.toLocaleDateString()}</span></p>
                    <p className='candidate-tasks__item__timestamp__time'>
                        Time: <img src={clockIc} alt="clock icon" /> <span>{transformDate(deadline)}
                        </span>
                    </p>
                </div>
            }
            </div>
            <button
                className={`candidate-tasks__item__button ${isExpired ? 'expired' : ''}`}
                onClick={() => { navigate(task.link) }}
                disabled={isExpired}
            >
                {isExpired ? "Expired" : task.buttonText}
            </button>
    </li>
}

export default PendingTasks

const transformDate = (date: Date): string => {
    let hours: number = date.getHours();
    let minutes: number | string = date.getMinutes();
    const newFormat: string = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // Display "0" as "12"
    minutes = minutes < 10 ? '0' + minutes : minutes.toString();

    return `${hours}:${minutes} ${newFormat}`;
};

const transformArray = (array) => {
    const tasks = []
    array.forEach(i => {
        const activeRound = i?.rounds?.length && findActiveRound(i.rounds)
        const isInterview = activeRound && typesOfInterview.includes(activeRound?.title);
        const isAssessment = activeRound && typesOfAssessment.includes(activeRound?.title);
        if (isInterview && !!activeRound?.eventId && activeRound.roomId) {
            tasks.push({
                deadline: '',
                title: activeRound?.title,
                description: `Join ${activeRound?.title} for ${i?.job?.title} job.`,
                startDate: activeRound?.eventStartTime,
                endDate: activeRound?.eventEndTime,
                buttonText: "Join Meeting",
                link: (activeRound.description === "Live Task/Coding Assessment" || activeRound.description === "") ? `/live-coding/online/${activeRound.roomId}` : `/live-coding/interview/${activeRound.roomId}`
            })
        }
        if (isAssessment) {
            let linkUrl;
            let assessment_deadline;
            if (activeRound.title === "Functional/Domain Assessment") {
                assessment_deadline = i?.job?.workflow?.domainDeadline;
                linkUrl = `/candidate/domain-assessment/${i?.jobId}/${i?.job?.workflow?.domainId}`;
            } else {
                assessment_deadline = i?.job?.workflow?.takeHomeTaskDeadline * 24;
                linkUrl = `/live-coding/offline/${i.jobId}/${i?.job?.workflow.takeHomeTaskId}`;
            }
            const deadlineStartDate = new Date(activeRound?.date?.start)
            deadlineStartDate.setHours(deadlineStartDate.getHours() + assessment_deadline)

            tasks.push({
                deadline: deadlineStartDate,
                title: activeRound?.title,
                description: `Your ${activeRound?.title} is pending for the position of ${i?.job?.title}. Complete it before the deadline.`,
                startDate: activeRound?.eventStartTime,
                endDate: activeRound?.eventEndTime,
                buttonText: "Start Test",
                link: linkUrl
            })
        }
    })

    return tasks
}