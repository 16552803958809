import { useEffect, useState } from 'react';
import fetchData from '../../hook/fetchData';
import { getEnv } from '@urecruits/api';
import { Stack, Skeleton } from '@mui/material';
import { DashboardEmptyDataComponent } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';
import { useNavigate } from 'react-router-dom';
const clockIc = require('../../image/icon/clock_ic.svg')
const {API_ASSESSMENT} = getEnv()

const typesOfInterview = ["HR Audio Video Interview", "Technical/Coding Assessment", "Senior HR Audio Video Interview", "Technical Audio Video Interview", "Live Task/Coding Assessment", "Take Home/Coding Assessment"]

const RescheduleTasks = () => {
    const [events,setEvents] = useState([])
    const [loading,setLoading] = useState(true)
    const [isEmpty,setIsEmpty] = useState(false)

    useEffect(()=>{
        fetchData(`${API_ASSESSMENT}/api/calendar/candidate/all-event?date=${new Date().toUTCString()}&timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`)
        .then(data=>{
            const eventList = data && !!data.length && data.filter((item) => typesOfInterview?.includes(item?.description))
            if(eventList && eventList?.length){
                setEvents(eventList)
            }else{
                setIsEmpty(true)
            }
            setLoading(false)
        }).catch(err=>{
            setLoading(false)
            setIsEmpty(true)
        })
    },[])
    return (
        <div className="candidate-tasks">
            <div className="candidate-tasks__headline">
                Reschedule Task {!!events.length && <span>- {events.length} right now</span>}
            </div>
            <ul className="candidate-tasks__list">
                {loading && <Stack height={"100%"}>
                    <Skeleton animation="wave" />
                </Stack>}
                {isEmpty && !loading && <DashboardEmptyDataComponent />}
                {!!events.length && !loading && events.map((i,index)=>{
                    return <ListItem event={i} key={index}/>

                })}
            </ul>
        </div>
    );
}

const ListItem = ({event})=>{
    const navigate = useNavigate()
    const startDate=  new Date(event.startDate)
    const endDate=  new Date(event.endDate)

    return <li className="candidate-tasks__item">
        <p className="candidate-tasks__item__name">{event.title}</p>
        <p className="candidate-tasks__item__company">{event.description}</p>
        <div className='candidate-tasks__item__timestamp'>
            <p>Date: <span>{startDate.toLocaleDateString()}</span></p>
            <p className='candidate-tasks__item__timestamp__time'>
                Time: <img src={clockIc} alt="clock icon" /> <span>{transformDate(startDate)} - {transformDate(endDate)} 
            </span>
            </p>
        </div>
        <button className="candidate-tasks__item__button" onClick={()=>{
            navigate(`/candidate/meeting-appointment?jobId=${event?.jobId}&candidateId=${event.candidateId}&roundType=${event.description}&reschedule=true&eventId=${event.eventId}`)
        }}>
            Reschedule Now
        </button>
    </li>
}

export default RescheduleTasks

const transformDate = (date: Date): string => {
    let hours: number = date.getHours();
    let minutes: number | string = date.getMinutes();

    // Check whether AM or PM
    const newFormat: string = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Display "0" as "12"
    minutes = minutes < 10 ? '0' + minutes : minutes.toString();

    return `${hours}:${minutes} ${newFormat}`;
};
