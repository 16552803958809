import { useNavigate, useParams } from "react-router-dom";
import { memo, useCallback, useLayoutEffect, useRef, useState } from "react";
import axios from "axios";
import { decodeToken, getEnv } from "@urecruits/api";
import { store, useTypedSelector } from "../store";
import FilterWrapper from "../components/Global/table/FilterWrapper";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
import { sortingFunc } from "../utils/sortingFuncForTables";
import TopFilterButton from "../components/Global/table/TopFilterButton";
import { getConfig, PaginationComponent, SearchFieldComponent, TheadItemComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import NoResultsSearch from "../components/Global/table/NoResultsSearch";
import {
	setSearchValueCompanyJobs,
	setSortTypeCompanyJobs,
	setSortByCompanyJobs,
	setTotalCountCompanyJobs,
	setEmptySearchCompanyJobs,
	setLimitCompanyJobs,
	setJobTableView,
	defaultFiltersCompanyJobs,
	setCompanyJobs,
	setSkillsFilterCompanyJobs,
	setSalaryYearFilterCompanyJobs,
	setSalaryMonthFilterCompanyJobs,
	setExperienceFilterCompanyJobs,
	setPostedOnFilterCompanyJobs,
	setEmptyTableCompanyJobs,
} from "../store/reducers/companyJobsReducer";
import { CompanyJobsTableTabsEnums } from "../enums/CompanyJobsTableEnums";
import { ICompanyJobItem } from "../types/redux/companyJobs";
import TbodyInnerJobs from "../components/CompanyJobs/Table/TBody/TbodyInnerJobs";
import MobileTable from "../components/CompanyJobs/Table/MobileTable/MobileTable";
import TableList from "../components/CompanyJobs/List/TableList";
import TableSVG from "../components/SVG/TableSVG";
import ListSVG from "../components/SVG/ListSVG";
import pagination from "@ucrecruits/globalstyle/components/Table/Pagination";
import ApplyPopup from "../components/CompanyJobs/Table/Popups/ApplyPopup";
import { CompanyJobsFilter } from "../components/CompanyJobs/CompanyJobsFilter";
import TableEmpty from "../components/Global/table/TableEmpty";

const { API_RECRUITMENT } = getEnv();

export function toJSONLocal(date: any) {
	let local = new Date(date);
	local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
	return local.toJSON().slice(0, 10);
}

export function toJSONLocalPlusDay(date: any) {
	let local = new Date(date);
	local.setDate(local.getDate() + 1);

	local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
	return local.toJSON().slice(0, 10);
}

const getTableJobs = store => store.jobs;
const getFiltersFunc = state => state.jobs.filters;
const getFilterDataFunc = state => state.jobs.filterInfo;
const getPaginationFunc = state => state.jobs.pagination;
const FilterItems = (state) => { return <CompanyJobsFilter activeTab={state} />; };

const FilterPopupInnerWrap = (setState) => {
	const filter = useTypedSelector(getFiltersFunc);
	const filterData = useTypedSelector(getFilterDataFunc);
	const pagination = useTypedSelector(getPaginationFunc);
	return <FilterWrapper
		setFilterPopup={setState}
		filterData={filterData}
		filters={filter}
		limit={pagination.limit}
		resetFunc={resetFilters}
		submitFunc={setJobsHandler}
	>
		{FilterItems}
	</FilterWrapper>;
};

const CompanyJobs = () => {
	const params = useParams();
	const table = useTypedSelector(getTableJobs);
	const naviagte = useNavigate();

	const [horizontalScrollState, setHorizontalScrollState] = useState(false);

	const tableRef = useRef(null);

	useLayoutEffect(() => {
		store.dispatch(setCompanyJobs([]));
		resetFilters(pagination);

		setJobsHandler(table.pagination.currentPage, table.pagination.limit, { ...table.filters, tenantId: params.tenantId });
	}, []);


	const filterArray = [table.filters.title, table.filters.locations, table.filters.experience, table.filters.salaryMonth, table.filters.salaryYear, table.filters.skills, table.filters.postedOn, table.filters.searchValue, table.filters.sortBy, table.filters.sortType, table.pagination];

	useTableClickAndDragScroll(tableRef);

	const tdOrderCallback = useCallback((value) => sortingFunc(table, value, setSortTypeCompanyJobs, setSortByCompanyJobs, setJobsHandler), filterArray);

	const setSearchCallback = useCallback((value) => {
		store.dispatch(setSearchValueCompanyJobs(value));
		setJobsHandler(1, table.pagination.limit, { ...table.filters, searchValue: value });
	}, filterArray);


	return (
		<>
			<div className={`company-jobs__table ${table.tableView === "list" ? "list" : ""}`}>
				<div className="company-jobs__head">
					<div className="company-jobs__head__left">
						{
							table.tableView === "list" && (
								<>
									<TopFilterButton>
										{FilterPopupInnerWrap}
									</TopFilterButton>
									<SearchFieldComponent
										searchValue={table.filters.searchValue}
										setSearchValue={setSearchCallback}
										placeholder={"Enter job title or key words"}
									/>
								</>
							)
						}
					</div>
					<div className="company-jobs__head__right">
						<div className="company-jobs__display">
							<div className={`company-jobs__display__item ${table.tableView === "table" ? "active" : ""}`}
								onClick={() => store.dispatch(setJobTableView("table"))}>
								<TableSVG />
							</div>
							<div className={`company-jobs__display__item ${table.tableView === "list" ? "active" : ""}`}
								onClick={() => store.dispatch(setJobTableView("list"))}>
								<ListSVG />
							</div>
						</div>
					</div>
				</div>
				{
					table.tableView === "table" && (
						<div className="table__wrapper">
							<div className="table__top">
								<div className="manage-team__top__left">
									<TopFilterButton>
										{FilterPopupInnerWrap}
									</TopFilterButton>
									<SearchFieldComponent
										searchValue={table.filters.searchValue}
										setSearchValue={setSearchCallback}
										placeholder={"Enter job title or key words"}
									/>
								</div>
							</div>
							{
								table.emptySearch ?
									<NoResultsSearch limit={table.pagination.limit} resetFunc={resetFilters} />
									: table.emptyTable ?
										<TableEmpty handler={() => naviagte("/candidate/companies") } cta={'Explore other Companies'}
											title={'No Jobs Available for This Company'}
											desc={'There are currently no job postings for this company.'}
										/>
										:
										<>
											<table className="table" ref={tableRef} onScroll={(e: any) => {
												if (e.target.scrollLeft > 10 && !horizontalScrollState) setHorizontalScrollState(() => true);
												else if (e.target.scrollLeft < 10 && horizontalScrollState) setHorizontalScrollState(() => false);
											}}>
												<thead className="table__thead">
													<td
														className={`table__td sticky ${horizontalScrollState ? "moved" : ""} table__td--thead manage-team__column__middle`}>
														<TheadItemComponent
															title={table.fixedTab.displayName}
															dbName={"title"}
															handler={tdOrderCallback}
														/>
													</td>
													{
														table.jobTabFilter.map((item) => {
															return item.active &&
																<td
																	className={item.displayName === CompanyJobsTableTabsEnums.JOB_TITLE ? `table__td  table__td--thead manage-team__column__middle` : `table__td  table__td--thead manage-team__column__default`}
																	key={item.id}>
																	<TheadItemComponent
																		title={item.displayName}
																		handler={item.dbName ? tdOrderCallback : null}
																		dbName={item.dbName} />
																</td>;
														})
													}
												</thead>
												<TbodyInnerJobs
													horizontalScrollState={horizontalScrollState}
												/>
											</table>
											<MobileTable />
											<PaginationComponent
												limit={table.pagination.limit}
												currentPage={table.pagination.currentPage}
												totalCount={table.pagination.totalCount}
												setCurrentPage={setJobsHandler}
												setLimit={setLimitHandler}
												filters={table.filters}
											/>
										</>
							}
						</div>
					)
				}
				{
					table.tableView === "list" && (
						<>
							{
								table.emptySearch ?
								<NoResultsSearch limit={table.pagination.limit} resetFunc={resetFilters} />
								: table.emptyTable ?
									<TableEmpty handler={() => naviagte("/candidate/companies") } cta={'Explore other Companies'}
										title={'No Jobs Available for This Company'}
										desc={'There are currently no job postings for this company. Once jobs are added, they will be displayed here.'}
									/>
									:
									<>
										<TableList />
										<PaginationComponent
											limit={table.pagination.limit}
											currentPage={table.pagination.currentPage}
											totalCount={table.pagination.totalCount}
											setCurrentPage={setJobsHandler}
											setLimit={setLimitHandler}
											filters={table.filters}
										/>
									</>
							}
						</>
					)
				}
			</div>
			{
				table.applyPopup.visible && (
					<ApplyPopup />
				)
			}
		</>
	);
};


export const setJobsHandler = (page: number, limit: number, filters: any) => {
	const getData = async () => {
		const userId = await decodeToken().then(data => data["https://urecruits.com/userId"]);

		filters.tenantId && await axios(`${API_RECRUITMENT}/api/job/public-jobs/company?tenantId=${filters.tenantId}&limit=${limit}&offset=${(page - 1) * limit}
		${filters.locations ? filters.locations.map((x: any) => `&locations=${x.value}`).join("") : ""}
		${filters.experience[0] ? "&experienceMin=" + filters.experience[0] : ""}
		${filters.experience[1] ? "&experienceMax=" + filters.experience[1] : ""}
		${filters.salaryMonth[0] ? "&salaryMonthMin=" + filters.salaryMonth[0] : ""}
		${filters.salaryMonth[1] ? "&salaryMonthMax=" + filters.salaryMonth[1] : ""}
		${filters.skills ? filters.skills.map((name: any) => `&skills=${name}`).join("") : ""}
		${filters.searchValue ? "&search=" + filters.searchValue : ""}
		${filters.postedOn ? "&createdAtFrom=" + toJSONLocal(filters.postedOn) : ""}
		${filters.postedOn ? "&createdAtTo=" + toJSONLocalPlusDay(filters.postedOn) : ""}
		${filters.sortBy ? "&sortBy=" + filters.sortBy : ""}
		${filters.sortType ? "&sortType=" + filters.sortType : ""}
		&currentUserId=${userId}
		`, getConfig())
			.then((res) => {
				store.dispatch(setTotalCountCompanyJobs(res.data.count));
				const jobs = transformArrayToTableData(res.data.rows);
				store.dispatch(setCompanyJobs(jobs));
				if (jobs.length === 0) {
					store.dispatch(setEmptyTableCompanyJobs(!isDefaultFilter(filters)));
					store.dispatch(setEmptySearchCompanyJobs(isDefaultFilter(filters)));
				} else {
					store.dispatch(setEmptyTableCompanyJobs(false));
					store.dispatch(setEmptySearchCompanyJobs(false));
				}
			});
	};
	getData().then();
};

const isDefaultFilter = (filters) => {
	return (
		!filters.searchValue ||
		filters.skills.length === 0 ||
		filters.salaryYear[0] === 0 ||
		filters.salaryYear[1] === 100000000 ||
		filters.salaryMonth[0] === 0 ||
		filters.salaryMonth[1] === 2000000 ||
		filters.experience[0] === 0 ||
		filters.experience[1] === 50 ||
		!filters.postedOn ||
		filters.locations.length === 0
	);
};

const setLimitHandler = (value: any) => {
	store.dispatch(setLimitCompanyJobs(value));
};

const transformArrayToTableData = (array: any): Array<ICompanyJobItem> => {
	return array.map(item => {
		return {
			id: item.id,
			title: item.title,
			locations: item.locations,
			experience: [item.experienceMin, item.experienceMax],
			salaryMonth: [item.salaryMonthMin, item.salaryMonthMax],
			salaryYear: [item.salaryYearMin, item.salaryYearMax],
			skills: item.skills,
			postedOn: item.createdAt,
			shortDescription: item.shortDescription,
			applicationForm: item.applicationForm,
			subscribes: item.subscribes[0] ? {
				applyJob: item.subscribes[0].applyJob,
				match: item.subscribes[0].match,
				saveJob: item.subscribes[0].saveJob,
				subscribeJob: item.subscribes[0].subscribeJob,
			} : [],
		};
	});
};

export default memo(CompanyJobs);


const resetFilters = (pagination) => {
	store.dispatch(setSkillsFilterCompanyJobs([]));
	store.dispatch(setSalaryYearFilterCompanyJobs([0, 1000000]));
	store.dispatch(setSalaryMonthFilterCompanyJobs([0, 20000]));
	store.dispatch(setExperienceFilterCompanyJobs([0, 50]));
	store.dispatch(setPostedOnFilterCompanyJobs(""));
	store.dispatch(setSortByCompanyJobs("id"));
	store.dispatch(setSortTypeCompanyJobs("ASC"));
	store.dispatch(setSearchValueCompanyJobs(""));
	setJobsHandler(1, pagination, defaultFiltersCompanyJobs);
};

