import { memo } from "react";
import { AreaChart as RechartsAreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from "recharts";
import { format, parseISO } from 'date-fns';
const options = [
	{value: "Last 7 days", label: "Last 7 days"},
	{value: "This Month", label: "This Month"},
];

const formatXAxis = (tickItem) => {
	return format(parseISO(tickItem), 'EEE'); // Format date to week day name
  };
const AreaChart = ({data, title}) => {
	// const [activeArea, setActiveArea] = useState({value: "Last 7 days", label: "Last 7 days"});
	// const [currentData, setCurrentData] = useState([])

	// //TODO: need to add func for calc This Week, This Month
	// useEffect(() => {
	// 	if(data.length>0){
	// 		setCurrentData(data?.slice(0,7))
	// 	}
	// 	// activeArea.value === 'This Month' && (setCurrentData(data?.slice(0,30)))
	// }, [])

	return ( 
		<div className="area-chart">
			<div className="area-chart__head">
				<p className="area-chart__head__headline">
					{title}
				</p>
				{/* <div className="area-chart__head__select">
					<ReactSelect
						options={options}
						value={activeArea}
						onChange={(value: any) => {
							setActiveArea(value);
						}}
						isSearchable={false}
						hideSelectedOptions={false}
						styles={selectSmallStyle}
						id="publishJobsSelect"
						instanceId="publishJobsSelect"
					/>
				</div> */}
			</div>
			{/* <div className={`area-chart__body ${activeArea.value === 'This Month' ? "month" : ""}`}> */}
			<div className={`area-chart__body`}>
				<div className="area-chart__body__inner">
					<ResponsiveContainer width="100%" height="100%">
						<RechartsAreaChart
							width={412}
							height={198}
							data={data}
							margin={{
								top: 10,
								right: 30,
								left: 0,
								bottom: 0,
							}}
						>
							<CartesianGrid strokeDasharray="4 4"/>
							<XAxis
								dataKey="data"
								tickFormatter={formatXAxis}
								style={{
									fontSize: "12px",
									textTransform: "uppercase",
									color: "#999EA5",
									lineHeight: "1.4",
								}}
							/>
							<YAxis
								style={{
									fontSize: "12px",
									color: "#999EA5",
								}}
							/>
							<Area type="monotone" dataKey="uv" stroke="#099C73" fill="#099C7340"/>
						</RechartsAreaChart>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	);
};

export default memo(AreaChart);