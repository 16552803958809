// import '../styles/_dialog.scss';
import { useNavigate } from "react-router";
import SuccessTickImg from '../image/icon/done-tick.png';


function Modal({ data }) {

  const navigate = useNavigate()
  return (
    <div className="popup" onClick={() => navigate("/")}>
      <div
        className="popup__step"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="popup__head">
        <p className="popup__head__headline">
        Appointment Confirmed
          </p>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
               className="popup__head__close"
               onClick={() => navigate("/")}
          >
            <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
        <div className="popup__body">
          <img className="popup__body__image" src={SuccessTickImg} alt="success-icon"/>
          <p className="popup__body__text">
          Your appointment for the <strong>{data}</strong> job has been successfully booked.
          </p>
        </div>
        <div className="popup__bottom center">
          <div
            className="popup__button button--filled"
            onClick={() => navigate("/")}
          >
            Got it!
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
