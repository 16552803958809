import { Link } from "react-router-dom";
import tempCompany from "../../image/temp-company.png";
import { PolarAngleAxis, RadialBar, RadialBarChart } from "recharts";
import { Stack, Skeleton } from '@mui/material';
import { store } from "../../store";
import { setFilterTypeCandidateJobs } from "../../store/reducers/candidateJobsReducer";
import { DashboardEmptyDataComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";


const JobMatches = ({matchedJobs,loading}) => {

        return (
                <div className="matches">
                        <div className="matches__head">
                                <p className="matches__head__headline">Top Matches({matchedJobs.length})</p>
                                <Link to="/candidate/jobs" className="matches__head__link" onClick={() => store.dispatch(setFilterTypeCandidateJobs("matched"))}>
                                        View all
                                </Link>
                        </div>
                        <ul className="matches__list">
                                {loading && <Stack height={"100%"}>
                                        <Skeleton animation="wave" />
                                </Stack>}
                                {!loading && !matchedJobs.length && <DashboardEmptyDataComponent />}
                                {!loading && matchedJobs?.map(item => {
                                        return (<li className="matches__item" key={item.id}>
                                                <div className="matches__item__left">
                                                        <img
                                                                src={tempCompany}
                                                                alt="temp company"
                                                                className="matches__item__icon"
                                                        />
                                                        <div className="matches__item__info">
                                                                <Link to={`/job/${item.id}`} className="matches__item__name">
                                                                        {item.title}
                                                                </Link>
                                                                <p className="matches__item__position">{item.company?.name}</p>
                                                        </div>
                                                </div>
                                                <div className="matches__progress">
                                                        <RadialBarChart
                                                                width={35}
                                                                height={35}
                                                                cx={18}
                                                                cy={18}
                                                                innerRadius={16}
                                                                outerRadius={16}
                                                                barSize={3}
                                                                data={[{ name: "L1", value: 50 }]}
                                                                startAngle={90}
                                                                endAngle={-270}>
                                                                <PolarAngleAxis
                                                                        type="number"
                                                                        domain={[0, 100]}
                                                                        angleAxisId={0}
                                                                        tick={false}
                                                                />
                                                                <RadialBar
                                                                        background
                                                                        dataKey="value"
                                                                        cornerRadius={40}
                                                                        fill="#029CA5"
                                                                />
                                                                <text
                                                                        x={18}
                                                                        y={18}
                                                                        textAnchor="middle"
                                                                        dominantBaseline="middle"
                                                                        className="matches__progress__label">
                                                                        {Math.round(item.match_percentage) + "%"}
                                                                </text>
                                                        </RadialBarChart>
                                                </div>
                                        </li>)
                                })}
                        </ul>
                </div>
        );
};

export default JobMatches;