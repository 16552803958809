import { useNavigate, useParams } from "react-router-dom";
import ClockIn from '../image/icon/clock_ic.svg';
import { useEffect, useState } from "react";
import axios from "axios";
import { getEnv } from "@urecruits/api";
import defaultAvatar from "../image/temp-company.png";
import { getConfig, ShowDomainAnswersComponent, SmallLoaderComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
 


const { API_RECRUITMENT,API_ASSESSMENT } = getEnv()

const renderItem = (item) => (
  item && (
    <div className="candidate-answer-sheet__sub-content__label__item">
      <div className="dot"></div>
      {item}
    </div>
  )
);

const renderDuration = (duration) => (
  duration && (
    <div>
      <img
        src={ClockIn}
        alt="clock ic"
        style={{
          minWidth: "12px",
          width: "12px",
          marginRight: "6px",
        }}
      />
      {`${duration}`}
    </div>
  )
);
const renderCompany = (company) => {
  return company && (
    <>
      <img
        src={!!company.avatar ? company.avatar : defaultAvatar} alt="avatar"
        className="candidate-answer-sheet__company__avatar" />
      <p className="candidate-answer-sheet__company__text">
        {company.name || ""}
      </p>
    </>
  )
}

interface Job{
title?:string ,
industry?:any,
company?:any,
assessmentName?:string,
duration?:string,
questions?:any[],
candidateAnswers?:any[],
totalScore?:number
}

const CandidateAnswerSheet = () => {

  const [isLoading,setIsLoading] =useState<boolean>(true)
  const [data, setData] = useState<Job>();
  const [totalScore,setTotalScore] = useState(0)
  const navigate = useNavigate()
 
  const { jobId, domainId } = useParams()

  useEffect(() => {
    async function fetchData(){
      const jobdata:Job={};
      await axios.get(`${API_RECRUITMENT}/api/job/public-job/${jobId}`, getConfig()).then(data => {
        const job = data.data;
        jobdata.title= job?.title,
        jobdata.industry= job?.industry,
        jobdata.company=job?.company,
        jobdata.assessmentName=job?.workflow?.title
        
      })
      axios.get(`${API_ASSESSMENT}/api/domain-result/candidate/${jobId}/${domainId}`,getConfig()).then(data=>{  
        jobdata.candidateAnswers=data.data?.candidateAnswers;
        jobdata.totalScore=data.data?.totalScore
      }).catch(er=>console.log(er))
      
      await axios.get(`${API_ASSESSMENT}/api/domain-questions/candidate/${domainId}`,getConfig()).then(data=>{
        jobdata.duration=data.data?.duration;
        jobdata.questions=data.data?.questions;
      })

      setData(jobdata)
      setIsLoading(false)
    }

    try{
      fetchData();
    }catch(err){
      setIsLoading(false)
      console.log("Error: ",err)
    }

  }, [])

  useEffect(()=>{
    if(data?.questions){
      const total = data?.questions.reduce((acc,next)=>acc+next.score,0);
      const percentage = (data.totalScore/total) * 100;
      if(percentage){
        setTotalScore(parseInt(percentage.toFixed(0)))
      }
    }
  },[data])

  return (
    <>
      {isLoading && <SmallLoaderComponent /> }
      {!isLoading  && (
        <div className="candidate-answer-sheet" >
          <div className="table-screen-top">
            <div className="table-screen-top__wrapper">
              <div>
                <p className="routes">
                  <span
                    onClick={() => {
                      navigate("/candidate/scoreboard");
                    }}
                    className="link">
                    Scoreboard
                  </span>
                  /
                  <span
                    onClick={() => {
                      navigate(`/candidate/scoreboard/${jobId}`)
                    }}
                    className="link">
                    {" "}
                    {data && data.title || ""}
                  </span>
                  {
                    data &&
                    <>
                      /
                      <span className="disable-link">
                        {" "}
                        Domain Assessment
                      </span>
                    </>
                  }
                </p>
                <div className="candidate-answer-sheet__head">
                  <h2 className="candidate-answer-sheet__head__headline">
                  {data && data.assessmentName || ""}
                  </h2>
                </div>
                {data &&
                  <div className="candidate-answer-sheet__sub-content">
                    <div className="candidate-answer-sheet__company">
                    {renderCompany(data.company)}
                    </div>
                    <div className="candidate-answer-sheet__sub-content__label">

                    {renderItem(data.industry?.label)}
                    {renderItem(data.title)}
                    {renderItem(renderDuration(data?.duration))}
                    {renderItem(`${totalScore}%`)}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="candidate-answer-sheet__questions">
            {
              (data && data.questions )? <ShowDomainAnswersComponent questions={data.questions} candidateAnswers={data.candidateAnswers} candidate/> :<>no data</>
            }
          </div>
        </div>
      )}
    </>
  );
}

export default CandidateAnswerSheet