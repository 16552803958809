import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import convertFromHTML from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { setIsStartTest } from "../../store/reducers/domainAssessmentReducer";
import { patchData } from "../../hook/fetchData";
import { getEnv } from "@urecruits/api";
import { useParams } from "react-router-dom";
const { API_ASSESSMENT } = getEnv()

const InstructionDialog = ({ data }) => {
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState()
  const { jobId } = useParams()

  useEffect(() => {
    const html = data?.instruction
    if (html) {
      const blocksFromHTML = convertFromHTML(html);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);

    }
  }, [data])

  return (
    <div className="assessment">
      <div
        className="assessment__container"
        onClick={(e) => e.stopPropagation()}>
        <div className="assessment__head">
          <h4 className="assessment__head__title">Instruction - {data?.name}</h4>
        </div>
        {!!data && <div className="assessment__instruction">
            This assessment is scheduled for a duration of {data?.duration}. Once the time expires, your responses will be automatically submitted.
        </div>}
        <div className="assessment__instruction">
        {data?.instruction&&<Editor
				editorState={editorState}
				wrapperClassName={`wrapper-class`}
				editorClassName="editor-class"
        toolbarHidden={true}
        readOnly={true} 
			/>}
        </div>
        <div className="assessment__footer">
          <button
            className="assessment__footer__btn"
            onClick={async () => {
              dispatch(setIsStartTest(true));
              const startTime = new Date().toUTCString()
              await patchData(`${API_ASSESSMENT}/api/test-manager?jobId=${jobId}&assessmentType=Domain Assessment&testStatus=In Progress&timeDuration=${startTime}`, {})
            }}>
            Start Test
          </button>
        </div>
      </div>
    </div>
  );
};

export default InstructionDialog;
